
import * as bootstrap from 'bootstrap';
import lozad from 'lozad'
import '~/style.css';
import '~/hex.css';

const lozad_observer = lozad();
lozad_observer.observe();

window.onload = function () {
  if (window.location.hash) {
    var el = document.getElementById(window.location.hash.replace('#', ''));
    if (el) {
      el.scrollIntoView();
    }
  }
}





const el = document.querySelector(".sticky-top")
const observer = new IntersectionObserver(
  ([e]) => e.target.classList.toggle("is-pinned", e.intersectionRatio < 1),
  { threshold: [1] }
);
if (el) observer.observe(el);

// Form Submit
const ajaxForm = document.querySelector('.ajax-form')
if (ajaxForm) {
  ajaxForm.addEventListener('submit', function (e) {
    e.preventDefault();
    let formData = new FormData(this);
    let parsedData = {};
    for (let name of formData) {
      if (typeof (parsedData[name[0]]) == "undefined") {
        let tempdata = formData.getAll(name[0]);
        if (tempdata.length > 1) {
          parsedData[name[0]] = tempdata;
        } else {
          parsedData[name[0]] = tempdata[0];
        }
      }
    }
    let options = {};
    switch (this.method.toLowerCase()) {
      case 'post':
        options.body = JSON.stringify(parsedData);
      case 'get':
        options.method = this.method;
        options.headers = { 'Content-Type': 'application/json' };
        break;
    }
    fetch("/submit.php?action=" + this.dataset.action, options).then(r => r.json()).then(data => {
      if (typeof (data['redirect']) != "undefined" && data['redirect'] != "") {
        window.location.href = data['redirect'];
        return;
      }
      if (data['ret'] == 'success') {
        var success = document.querySelector('.alert-success');
        if (success) success.style.display = 'block';
        var hidden = document.querySelector('.alert-hidden');
        if (hidden) hidden.style.display = 'none';
        this.reset();
      }
      else {
        document.querySelector('.alert-danger').style.display = 'block';
      }
    });
  });
}

const emojiElement = document.querySelector('#zgt-logo');
if (emojiElement) {
  emojiElement.addEventListener('click', function (event) {
    if (!this.dataset.hasOwnProperty('emojiActivated')) {
      this.dataset.emojiActivated = 'true';
      this.classList.add('animate__animated', 'animate__pulse')
      // Aufräumen
      function cleanUp() {
        var clickEmoji = document.getElementsByClassName('click-emoji');
        var totalEmoji = clickEmoji.length;
        Array.from(clickEmoji).forEach(function (emoji, index) {
          var maximumDelay = totalEmoji.length > 10 ? 1000 : 400;
          if (index === 0) {
            emoji.style['animation-delay'] = 0 + 'ms';
          } else {
            emoji.style['animation-delay'] = Math.round(Math.random() * maximumDelay) + 'ms';
          }
          emoji.style['animation-duration'] = Math.max(Math.round(Math.random() * 700), 100) + 'ms';
          emoji.addEventListener('animationend', function () {
            document.body.removeChild(emoji);
          });
          emoji.classList.add('animate__bounceOutDown');
        });
      }
      // Ausführen
      const currentDate = new Date();
      const currentMonth = currentDate.getMonth();
      const currentDay = currentDate.getDate();
      /*
      var emojisList = ['🐹', '🐢', '👾', '🐋', '🐧', '🤖', '🛸', '🙈', '🐵', '🙊', '🙉'];
      var emojisList = [''];
      if (currentMonth == 9 && [31, 30, 29, 28].indexOf(currentDay) !== -1) {
        emojisList = ['👻', '🎃', '🧟‍♀️'];
      }
      if (currentMonth == 11 && [21, 22, 23, 24, 25, 26].indexOf(currentDay) !== -1) {
        emojisList = ['❄️', '🎅', '🎁'];
      }
      */
      function startEmoji(event) {
        //var randomNumber = Math.round(Math.random() * emojisList.length);
        var span = document.createElement('span');
        //span.textContent = emojisList[randomNumber];
        span.className = 'emoji click-emoji emoji-zago';
        span.style.left = event.clientX + 'px';
        span.style.top = event.clientY + 'px';
        span.style.position = 'fixed';
        document.body.appendChild(span);
      }
      let start_flag = null;
      let next_flag = 300;
      window.addEventListener('mousemove', function (event) {
        if (!start_flag) {
          start_flag = { x: event.clientX, y: event.clientY };
          return;
        }
        let dx = event.clientX - start_flag.x;
        let dy = event.clientY - start_flag.y;
        let distance = Math.sqrt(dx * dx + dy * dy);
        if (distance >= next_flag) {
          next_flag = Math.floor(Math.random() * 101) + 50;
          startEmoji(event);
          start_flag = null;
        }
      });
      document.body.addEventListener('click', cleanUp);
    }
  });
}




